var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{ref:"validationForm",on:{"validated":_vm.updatePlayer},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var observer = ref.observer;
return [_c('Modal',_vm._g(_vm._b({ref:"modal",attrs:{"static":"","value":_vm.value,"title":(_vm.type + " Player"),"submit-buttons":{
      loading: _vm.form.isLoading,
      invalid: observer.failed,
      save: {
        text: !_vm.team ? 'Yes': undefined
      }
    }},on:{"hidden":function($event){return _vm.form.reset()},"save":function($event){return _vm.$refs.validationForm.validate()}},scopedSlots:_vm._u([{key:"details",fn:function(){return [_c('b-card-title',[_vm._v(" "+_vm._s(_vm.player.full_name)+" ")]),_c('b-card-text',{staticClass:"my-2"},[(_vm.team)?[_vm._v(" "+_vm._s(_vm.type)+" from "),_c('b',[_vm._v(_vm._s(_vm.team.name || 'Free Agent'))]),_vm._v(" to: ")]:[_vm._v(" Are you sure you want to make this player a "),_c('b',[_vm._v("Free Agent")]),_vm._v("? ")]],2)]},proxy:true}],null,true)},'Modal',_vm.$attrs,false),_vm.$listeners),[(_vm.team)?[_c('select-team',{attrs:{"rules":_vm.team ? 'is_not:Select' : null,"include-free-agent":!_vm.team},on:{"update:team":function($event){_vm.newTeam = $event}},model:{value:(_vm.form.team_id),callback:function ($$v) {_vm.$set(_vm.form, "team_id", $$v)},expression:"form.team_id"}}),(_vm.form.team_id)?_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('form-validation-jersey-number',{model:{value:(_vm.form.jersey_number),callback:function ($$v) {_vm.$set(_vm.form, "jersey_number", $$v)},expression:"form.jersey_number"}})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('select-position',{attrs:{"sport":_vm.event.sport},on:{"position-selected":function($event){_vm.position = $event}},model:{value:(_vm.form.position_id),callback:function ($$v) {_vm.$set(_vm.form, "position_id", $$v)},expression:"form.position_id"}})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('select-roster-status',{model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1):_vm._e()]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }