<template>
  <b-form-group
    label="Position"
    label-for="position"
  >
    <b-form-select
      id="position"
      name="position"
      value-field="id"
      text-field="abbreviation"
      :value="value"
      :options="options"
      v-bind="$attrs"
      v-on="$listeners"
      @input="updateSelected"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue'

import store from '@/store'
import useFlash from '@/composables/useFlash'
import { mapState } from 'vuex'

const { flashError } = useFlash()

export default {
  components: {
    BFormGroup,
    BFormSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    sport: {
      required: true,
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('app/sport', ['positions']),
    options() {
      return [
        { id: null, abbreviation: '-' },
        ...this.positions,
      ]
    },
  },
  async created() {
    // Cache positions for the same sport.
    if (this.positions.length && store.state.app.sport.name === this.sport) {
      return
    }

    try {
      await store.dispatch('app/sport/getPositions')
    } catch (error) {
      flashError(error)
    }
  },
  methods: {
    updateSelected(id) {
      this.$emit('input', id)
      this.$emit('position-selected', this.options.find(position => position.id === id))
    },
  },
}
</script>

<style scoped>

</style>
