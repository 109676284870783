<template>
  <section v-if="season">
    <!-- Add New Player -->
    <add-player
      v-if="addPlayer"
      @add-player="handleAddPlayer"
      @cancel="addPlayer = false"
    />

    <!-- Edit Player -->
    <edit-player
      v-model="editPlayer"
      :player="player"
      @update-player="handleUpdatePlayer"
      @transfer-player="handleTransferPlayer"
      @cancel="editPlayer = false"
    />

    <b-card no-body>
      <card-header-filters
        ref="cardHeaderFilters"
        title="Players"
        :button="{ show: !addPlayer }"
        :import-button="true"
        :filters="filters"
        @add="addPlayer = true"
        @import="importPlayers = true"
        @reset-filters="handleResetFilter"
      >
        <template #filters>
          <!-- Name Filter -->
          <b-form-group
            label="Name"
            label-for="name-filter"
          >
            <b-form-input
              id="name-filter"
              v-model="filters.name"
              type="search"
              placeholder="Search..."
              class="d-inline-block mr-1"
              debounce="500"
              aria-autocomplete="none"
              @update="getPlayers"
            />
          </b-form-group>

          <!-- Division Filter -->
          <select-division
            v-model="filters.division"
            is-filter
            @input="$nextTick(() => getPlayers())"
          />

          <!-- Team Filter -->
          <select-team
            v-model="filters.team"
            is-filter
            :filter-by-division="filters.division"
            :team.sync="team"
            @change="getPlayers"
          />

          <!-- Status Filter -->
          <select-roster-status
            v-model="filters.status"
            is-filter
            @change="getPlayers"
          />

        </template>
      </card-header-filters>

      <!-- Table -->
      <table-list
        primary-key="roster_id"
        responsive="lg"
        :items="filteredPlayers"
        :current-page="1"
        :per-page="metaData.perPage + duplicatedPlayersCount"
        :fields="fields"
        :busy="isLoading"
        :meta-data="metaData"
        item-name="player"
        delete-button-text="Remove from Season"
        :delete-modal="({ item }) => ({
          title: 'Remove Player From Season',
          itemName: item.full_name,
          text: `Are you sure you want to permanently remove this player and their stats from <b>${item.roster.team ? item.roster.team.name : 'Free Agent'}</b> for the <b>${season.name}</b> season?`,
          endpoint: `/rosters/${item.roster.id}`,
          flashMessage: `${item.full_name} has been successfully removed!`,
          afterDelete: () => handleDeletePlayer(item),
        })"
        @change-per-page="getPlayers"
        @change-pagination="updatePage"
      >
        <!-- Name cell -->
        <template #cell(name)="{ item }">
          <b-link @click.prevent="editPlayerModal(item)">
            {{ item.full_name }}
          </b-link>
        </template>

        <!-- Status cell -->
        <template #cell(roster.status)="{ item }">
          <b-badge
            v-if="item.roster.team"
            pill
            class="text-capitalize"
            :variant="`light-${resolveUserStatusVariant(item.roster.status)}`"
          >
            {{ item.roster.status }}
          </b-badge>
        </template>

        <!-- Actions cell -->
        <template #actions="{ item }">
          <b-button
            text
            size="sm"
            variant="flat-primary"
            :disabled="item.roster.status === 'traded' || item.roster.status === 'released'"
            @click="showTradePlayerModal(item, item.roster.team || {})"
          >
            {{ !item.roster.team ? 'Assign' : 'Trade' }}
          </b-button>

          <b-button
            text
            size="sm"
            variant="flat-primary"
            :disabled="!item.roster.team || item.roster.status === 'traded' || item.roster.status === 'released'"
            @click="showTradePlayerModal(item, null)"
          >
            Release
          </b-button>
        </template>
      </table-list>
    </b-card>

    <!-- Modals -->
    <modal-player-transfer
      v-model="modalTradePlayer"
      :team="team"
      :player="player"
      @transfer-player="handleTransferPlayer"
      @cancel="modalTradePlayer = false"
    />

    <modal-import-players
      v-model="importPlayers"
      title="Import Players"
      :end-point="`seasons/${this.$store.state.event.seasons.season.id}/rosters/import`"
      @cancel="importPlayers = false"
      @import-successful="handleImportSuccessful"
    />

  </section>
</template>

<script>
import {
  BCard, BLink, BFormGroup, BFormInput, BButton, BBadge,
} from 'bootstrap-vue'
import AddPlayer from '@/views/events/manage/players/EventManagePlayerAdd.vue'
import EditPlayer from '@/views/events/manage/players/EventManagePlayerEdit.vue'
import CardHeaderFilters from '@/components/cards/CardHeaderFilters.vue'
import SelectDivision from '@/components/forms/selects/SelectDivision.vue'
import SelectTeam from '@/components/forms/selects/SelectTeam.vue'
import SelectRosterStatus from '@/components/forms/selects/SelectRosterStatus.vue'
import TableList from '@/components/TableList.vue'
import ModalPlayerTransfer from '@/components/modals/player/ModalPlayerTransfer.vue'
import ModalImportPlayers from '@/components/modals/ModalImport.vue'

import { mapState } from 'vuex'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventPlayers',
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BButton,
    BBadge,
    AddPlayer,
    EditPlayer,
    CardHeaderFilters,
    SelectTeam,
    SelectDivision,
    SelectRosterStatus,
    TableList,
    ModalPlayerTransfer,
    ModalImportPlayers,
  },
  data() {
    return {
      isLoading: true,
      addPlayer: false,
      editPlayer: false,
      modalTradePlayer: false,
      importPlayers: false,
      player: {},
      team: null,
      pageOptions: [10, 25, 50, 100],
      metaData: {
        currentPage: 1,
        perPage: localStorage.getItem('perPage') || 10,
      },
      players: [],
      fields: [
        'name',
        {
          key: 'roster.jersey_number',
          label: 'number',
        },
        {
          key: 'roster.team',
          label: 'team',
          formatter: (value, key, item) => this.teamFormatter(item.roster.team),
        },
        {
          key: 'roster.position',
          label: 'position',
          formatter: (value, key, item) => item.roster.position?.name,
        },
        {
          key: 'roster.status',
          label: 'status',
        },
      ],
      filters: {
        name: null,
        division: 'All',
        team: 'All',
        status: 'active',
      },
    }
  },
  computed: {
    ...mapState('event/seasons', ['seasons', 'season']),
    filteredPlayers() {
      return this.players.filter(player => !this.playerIsFiltered(player))
    },
    duplicatedPlayers() {
      const players = []
      const duplicated = []

      this.players.forEach(player => {
        if (players.find(p => p.id === player.id)) {
          duplicated.push(player)
        } else {
          players.push(player)
        }
      })

      return duplicated
    },
    duplicatedPlayersCount() {
      return this.duplicatedPlayers.length
    },
  },
  watch: {
    season: {
      immediate: true,
      handler() {
        if (!this.season) {
          return
        }

        this.getPlayers()
      },
    },
  },
  methods: {
    // Modals
    editPlayerModal(player) {
      this.player = player
      this.editPlayer = true
    },
    showTradePlayerModal(player, team) {
      this.player = player
      this.team = team
      this.modalTradePlayer = true
    },
    handleImportSuccessful() {
      this.importPlayers = false
      this.getPlayers()
    },
    playerIsFiltered(player) {
      let filtered = false

      // Player's name does not match filter.
      if (this.filters.name && !player.full_name.toLowerCase().includes(this.filters.name)) {
        filtered = true
      }

      // Player's team doesn't match filter.
      if (this.filters.team !== 'All' && !(player.roster.team?.id == this.filters.team)) {
        filtered = true
      }

      // Team's division doesn't match filter.
      if (this.filters.division !== 'All' && !(player.roster.division?.id === this.filters.division)) {
        filtered = true
      }

      // Status does not match filter.
      if (this.filters.status !== 'All' && !(player.roster.status === this.filters.status)) {
        filtered = true
      }

      return filtered
    },
    handleTransferPlayer(roster) {
      // TODO: Add new record instead of updating.
      const index = this.players.findIndex(player => player.roster.id === this.player.roster.id)

      if (this.filters.team !== 'All') {
        // Remove player from team.
        this.players.splice(index, 1)
      } else {
        // Update player's roster.
        this.players.splice(index, 1, {
          ...this.player,
          roster,
        })
      }

      this.modalTradePlayer = false
      this.editPlayer = false
    },
    handleAddPlayer(player) {
      if (this.playerIsFiltered(player)) {
        // Set the filters to show the new player.
        this.handleResetFilter({
          ...this.$refs.cardHeaderFilters.$refs.buttonFilter.originalFilters,
          team: player.roster.team ? player.roster.team.id : null,
        })
        return
      }

      this.players.push(player)
      this.sortPlayers()
      this.metaData.total += 1
    },
    handleUpdatePlayer({ player, rosterId }) {
      this.players.splice(
        this.players.findIndex(p => p.roster.id === rosterId),
        1,
        player,
      )

      // If player has more than one roster, update player's info for all rosters.
      if (this.duplicatedPlayers.filter(p => p.id === player.id).length) {
        this.players.forEach((p, index) => {
          if (p.id === player.id) {
            this.players.splice(
              index,
              1,
              {
                ...player,
                roster: p.roster,
              },
            )
          }
        })
      }

      this.sortPlayers()
      this.editPlayer = false
    },
    handleDeletePlayer(player) {
      this.players.splice(this.players.findIndex(p => p.roster.id === player.roster.id), 1)
      this.metaData.total += -1
      this.metaData.to += -1
    },
    // Formatting
    teamFormatter(team) {
      return team ? team.name : 'Free Agent'
    },
    resolveUserStatusVariant(status) {
      switch (status) {
        case 'inactive':
          return 'danger'
        case 'active':
          return 'success'
        case 'traded':
          return 'dark'
        case 'released':
          return 'dark'
        default:
          return 'primary'
      }
    },
    sortPlayers() {
      // Resort players alphabetically.
      this.players.sort((a, b) => {
        const nameA = a.first_name.toUpperCase()
        const nameB = b.first_name.toUpperCase()

        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
        // names must be equal
        return 0
      })
    },
    // Pagination / Ajax
    handleResetFilter(filters) {
      this.filters = filters
      this.getPlayers()
    },
    updatePage(pageNumber) {
      this.metaData.currentPage = pageNumber
      this.getPlayers()
    },
    buildQuery() {
      const query = new URLSearchParams({
        season_id: this.season.id,
        per_page: this.metaData.perPage,
        page: this.metaData.currentPage,
      })

      if (this.filters.name) {
        query.append('full_name', this.filters.name)
      }

      if (this.filters.division !== 'All') {
        query.append('division_id', this.filters.division)
      }

      if (this.filters.team !== 'All') {
        query.append('team_id', this.filters.team)
      }

      if (this.filters.status !== 'All') {
        query.append('status', this.filters.status)
      }

      return query
    },
    async getPlayers() {
      this.isLoading = true

      try {
        const { data } = await this.$http.get(`/players?${this.buildQuery().toString()}`)

        this.players = []

        data.data.forEach(player => {
          if (this.filters.team !== 'All') {
            this.players.push({
              ...player,
              roster: {
                ...player.roster,
                team: this.team, // Add the selected team to each player.
              },
            })
          } else {
            // Add each roster a player is on as another record, so they will appear multiple times.
            player.rosters.forEach(roster => {
              this.players.push({
                ...(({ rosters, ...p }) => p)(player), // Remove the rosters field from player.
                roster,
              })
            })
          }
        })

        this.metaData = {
          currentPage: data.meta.current_page,
          perPage: data.meta.per_page,
          to: data.meta.to + this.duplicatedPlayersCount,
          total: data.meta.total + this.duplicatedPlayersCount,
        }
      } catch (error) {
        flashError(error)
        this.$Progress.fail()
      }

      this.isLoading = false
      this.$Progress.finish()
    },
  },
}
</script>

<style scoped>

</style>
