<template>
  <validation-form
    ref="validationForm"
    v-slot="{ observer }"
    @validated="updatePlayer"
  >
    <Modal
      ref="modal"
      static
      :value="value"
      :title="`${type} Player`"
      :submit-buttons="{
        loading: form.isLoading,
        invalid: observer.failed,
        save: {
          text: !team ? 'Yes': undefined
        }
      }"
      v-bind="$attrs"
      @hidden="form.reset()"
      @save="$refs.validationForm.validate()"
      v-on="$listeners"
    >
      <!-- Details -->
      <template #details>
        <b-card-title>
          {{ player.full_name }}
        </b-card-title>

        <b-card-text class="my-2">
          <template v-if="team">
            {{ type }} from <b>{{ team.name || 'Free Agent' }}</b> to:
          </template>
          <template v-else>
            Are you sure you want to make this player a <b>Free Agent</b>?
          </template>
        </b-card-text>
      </template>

      <template v-if="team">
        <!-- Team -->
        <select-team
          v-model="form.team_id"
          :rules="team ? 'is_not:Select' : null"
          :include-free-agent="!team"
          @update:team="newTeam = $event"
        />

        <b-row v-if="form.team_id">
          <!-- Jersey Number -->
          <b-col sm="4">
            <form-validation-jersey-number v-model="form.jersey_number" />
          </b-col>

          <!-- Position -->
          <b-col sm="4">
            <select-position
              v-model="form.position_id"
              :sport="event.sport"
              @position-selected="position = $event"
            />
          </b-col>

          <!-- Status -->
          <b-col sm="4">
            <select-roster-status v-model="form.status" />
          </b-col>
        </b-row>
      </template>
    </Modal>
  </validation-form>
</template>

<script>
import {
  BCardText, BCardTitle, BRow, BCol,
} from 'bootstrap-vue'
import Modal from '@/components/modals/Modal.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormValidationJerseyNumber from '@/components/forms/validation/FormValidationJerseyNumber.vue'
import SelectTeam from '@/components/forms/selects/SelectTeam.vue'
import SelectPosition from '@/components/forms/selects/SelectPosition.vue'
import SelectRosterStatus from '@/components/forms/selects/SelectRosterStatus.vue'

import Form from '@/forms/Form'
import { mapState } from 'vuex'

export default {
  components: {
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    Modal,
    ValidationForm,
    FormValidationJerseyNumber,
    SelectTeam,
    SelectPosition,
    SelectRosterStatus,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    player: {
      required: true,
      type: Object,
    },
    team: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: new Form({
        team_id: 'Select',
        status: 'active',
        jersey_number: null,
        position_id: null,
      }),
      newTeam: null,
      position: null,
    }
  },
  computed: {
    ...mapState('event', ['event']),
    ...mapState('event/seasons', ['season']),
    type() {
      if (!this.team) {
        return 'Release'
      }

      return Object.keys(this.team).length ? 'Trade' : 'Assign'
    },
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    updatePlayer() {
      // Player is being released to free agent.
      if (!this.team) {
        this.newTeam = null
        this.form.team_id = null
      }

      this.form.put(`/rosters/${this.player.roster.id}/transfer`)
        .then(response => {
          this.$emit('transfer-player', {
            ...response.data,
            position: this.position,
            team: this.newTeam,
            division: this.newTeam?.division,
          })
        })
    },
  },
}
</script>
