<template>
  <validation-form
    ref="validationForm"
    v-slot="{ observer }"
    :default-submit-buttons="false"
    @validated="updatePlayer"
  >
    <!-- Prop "no-enforce-focus" is needed for date picker otherwise user cannot type the year in the input field -->
    <Modal
      v-if="Object.keys(player).length > 0"
      title="Edit Player"
      size="lg"
      scrollable
      no-enforce-focus
      :value="value"
      @show="handleShow"
      v-on="$listeners"
    >
      <template #modal-footer>
        <submit-buttons
          :loading="form.isLoading"
          :invalid="observer.failed || isLoading"
          @save="$refs.validationForm.validate()"
          v-on="$listeners"
        >
          <template #prepend>
            <b-button
              class="mr-1"
              :to="{ name: 'player.edit', params: { player: player.id }, query: { event: event.id } }"
            >
              More Info
            </b-button>
          </template>
        </submit-buttons>
      </template>

      <!-- Roster Info -->
      <b-card
        no-body
        class="shadow-none mt-2 mb-0"
      >
        <b-card-title>Roster Info</b-card-title>
        <b-row>
          <!-- Season -->
          <b-col sm="6">
            <select-season
              label="Season*"
              :value="season.id"
              :disabled="true"
            />
          </b-col>

          <!-- Team -->
          <b-col sm="6">
            <select-team
              rules="is_not:Select"
              mode="lazy"
              :value="teamId"
              :team.sync="team"
              :disabled="['traded', 'released'].includes(form.roster.status)"
              :include-free-agent="!player.roster.team"
              @input="form.roster.team_id = $event"
              @change.once="handleTeamChange"
            >
              <template #append-form-group>
                <p
                  v-if="transferPlayer && form.roster.team_id"
                  class="text-warning mt-50 font-small-3"
                >
                  <span v-if="hasStats">
                    This player has stats on the {{ player.roster.team.name }}. Switching teams will result in a trade.
                  </span>

                  <span v-else>
                    This player was released from another team. Switching teams will assign this player to {{ team.name }}.
                  </span>
                </p>
              </template>
            </select-team>
          </b-col>
        </b-row>

        <b-row v-if="teamId">
          <!-- Jersey Number -->
          <b-col sm="4">
            <form-validation-jersey-number v-model="form.roster.jersey_number" />
          </b-col>

          <!-- Position -->
          <b-col sm="4">
            <select-position
              v-model="form.roster.position_id"
              :sport="event.sport"
              @position-selected="position = $event"
            />
          </b-col>

          <!-- Status -->
          <b-col sm="4">
            <select-roster-status v-model="form.roster.status" />
          </b-col>
        </b-row>
      </b-card>

      <hr
        role="separator"
        aria-orientation="horizontal"
        class="dropdown-divider"
      >

      <!-- Player Info -->
      <b-card
        no-body
        class="shadow-none mt-2 mb-0"
      >
        <b-card-title>Player Info</b-card-title>
        <form-player-info :player="form" />
      </b-card>
    </Modal>
  </validation-form>
</template>

<script>
import {
  BCard, BCardTitle, BCol, BRow, BButton,
} from 'bootstrap-vue'
import Modal from '@/components/modals/Modal.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import SelectSeason from '@/components/forms/selects/SelectSeason.vue'
import SelectTeam from '@/components/forms/selects/SelectTeam.vue'
import SelectPosition from '@/components/forms/selects/SelectPosition.vue'
import SelectRosterStatus from '@/components/forms/selects/SelectRosterStatus.vue'
import FormValidationJerseyNumber from '@/components/forms/validation/FormValidationJerseyNumber.vue'
import FormPlayerInfo from '@/components/forms/FormPlayerInfo.vue'

import { mapState } from 'vuex'
import Form from '@/forms/Form'
import useFlash from '@/composables/useFlash'
import { copyExistingProperties } from '@/helpers/objects'

const { flashError } = useFlash()

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BButton,
    Modal,
    SubmitButtons,
    ValidationForm,
    SelectSeason,
    SelectTeam,
    SelectRosterStatus,
    SelectPosition,
    FormValidationJerseyNumber,
    FormPlayerInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    player: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      position: null,
      team: null,
      hasStats: false,
      wasOnAnotherRoster: false,
      isLoading: false,
      form: new Form({
        roster: {
          id: null,
          season_id: null,
          team_id: null,
          jersey_number: null,
          position_id: null,
          status: 'active',
        },
        first_name: '',
        last_name: '',
        photo: undefined,
        photo_url: '',
        gender: null,
        height: null,
        weight: null,
        birthdate: null,
        school: null,
        email: null,
        phone_number: null,
        gpa: null,
      }, true),
    }
  },
  computed: {
    ...mapState('event', ['event']),
    ...mapState('event/seasons', ['season']),
    teamId() {
      return this.team ? this.team.id : null
    },
    transferPlayer() {
      return this.hasStats || this.wasOnAnotherRoster
    },
  },
  watch: {
    team(value) {
      if (!value) {
        this.form.roster.jersey_number = null
        this.form.roster.position_id = null
        this.position = null
      }
    },
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    handleShow() {
      this.hasStats = false
      this.wasOnAnotherRoster = false
      this.position = this.player.roster.position
      this.team = this.player.roster.team

      Object.assign(this.form, {
        ...this.player,
        roster: {
          ...copyExistingProperties(this.form.roster, this.player.roster),
          season_id: this.season.id,
          team_id: this.player.roster.team?.id || null,
          position_id: this.player.roster.position?.id || null,
        },
      })
    },
    async checkIfPlayerWasOnAnotherRoster() {
      const response = await this.$http.get(`/players/${this.player.id}?season_id=${this.season.id}`)

      if (response.data.data.rosters.length > 1) {
        this.wasOnAnotherRoster = true
      }
    },
    async checkIfPlayerHasStats() {
      const response = await this.$http.get(`/sports/${this.event.sport}/players/${this.player.id}/stats?season_id=${this.season.id}&team_id=${this.player.roster.team.id}`)

      response.data.data.stats_categories.forEach(category => {
        if (category.stats.length) {
          this.hasStats = true
        }
      })
    },
    async handleTeamChange(value) {
      // On same team.
      if (value === this.player.roster.team?.id) {
        return
      }

      this.isLoading = true

      try {
        // If player is a free agent check to see if they were on another team so a transfer can be made.
        if (!this.player.roster.team?.id) {
          await this.checkIfPlayerWasOnAnotherRoster()
        } else {
          await this.checkIfPlayerHasStats()
        }
      } catch (error) {
        flashError(error)
      }

      this.isLoading = false
    },
    async updatePlayer() {
      // Don't pass up team id if player is on the same team or should be transferred.
      if (this.player.roster.team?.id === this.form.roster.team_id || this.transferPlayer) {
        delete this.form.roster.team_id
      }

      const { data } = await this.form.post(`/players/${this.player.id}`, false, true)

      this.$emit('update-player', {
        player: {
          ...this.player,
          ...data,
        },
        rosterId: this.player.roster.id,
      })

      if (this.transferPlayer) {
        // Add back team_id key to roster for transfer.
        this.form.roster.team_id = this.team.id

        // Transfer player.
        new Form(this.form.roster)
          .put(`/rosters/${this.player.roster.id}/transfer`)
          .then(response => {
            this.$emit('transfer-player', {
              ...response.data,
              position: this.position,
              team: this.team,
              division: this.team?.division,
            })
          })
      }
    },
  },
}
</script>
