<template>
  <form-validation-field
    :value="value"
    rules="max:4"
    vid="jersey_number"
    name="jersey_number"
    label="Number"
    placeholder="#"
    aria-autocomplete="none"
    type="number"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    FormValidationField,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
