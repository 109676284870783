var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('modal-card',{attrs:{"title":"Add Player"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isCard = ref.isCard;
return [_c('validation-form',{ref:"validationForm",on:{"before-validation":_vm.validateName,"validated":_vm.checkForDuplicatePlayers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var observer = ref.observer;
return [_c('b-form-row',[_vm._l(({ firstName: 'first_name', lastName: 'last_name' }),function(name,key){return _c('b-col',{key:key,attrs:{"sm":"6","md":"2"}},[_c('form-validation-auto-complete',{ref:"name",refInFor:true,attrs:{"name":_vm.inputProps[key].name,"input-props":_vm.inputProps[key],"suggestions":_vm.suggestionsWithoutDuplicatePlayers,"label":_vm.inputProps[key].placeholder + '*',"render-suggestion":_vm.renderSuggestion,"get-suggestion-value":function (ref) {
    var item = ref.item;

    return item[name];
},"is-loading":_vm.loadingSuggestions[name],"rules":"required"},on:{"input":function($event){return _vm.handleNameInput(name)},"selected":_vm.handleSelectedPlayer},model:{value:(_vm.form[name]),callback:function ($$v) {_vm.$set(_vm.form, name, $$v)},expression:"form[name]"}})],1)}),_c('b-col',{attrs:{"sm":"6","md":"2","lg":"2"}},[_c('select-team',{attrs:{"rules":"is_not:Select","team":_vm.team},on:{"update:team":function($event){_vm.team=$event}},model:{value:(_vm.form.roster.team_id),callback:function ($$v) {_vm.$set(_vm.form.roster, "team_id", $$v)},expression:"form.roster.team_id"}})],1),_c('b-col',{attrs:{"sm":"2","md":"1","lg":"1"}},[_c('form-validation-jersey-number',{model:{value:(_vm.form.roster.jersey_number),callback:function ($$v) {_vm.$set(_vm.form.roster, "jersey_number", $$v)},expression:"form.roster.jersey_number"}})],1),_c('b-col',{attrs:{"sm":"4","md":"1-5","lg":"1"}},[_c('select-position',{attrs:{"sport":_vm.event.sport},on:{"position-selected":function($event){_vm.position = $event}},model:{value:(_vm.form.roster.position_id),callback:function ($$v) {_vm.$set(_vm.form.roster, "position_id", $$v)},expression:"form.roster.position_id"}})],1),_c('portal',{attrs:{"to":"modal-footer-add-player","disabled":isCard}},[_c('submit-buttons',_vm._g({staticClass:"mt-sm-2 ml-sm-75",attrs:{"loading":_vm.form.isLoading,"invalid":observer.failed || _vm.debounceWaiting},on:{"save":function($event){return _vm.$refs.validationForm.validate()}}},_vm.$listeners))],1)],2)]}}],null,true)})]}},{key:"modal-footer",fn:function(){return [_c('portal-target',{attrs:{"name":"modal-footer-add-player"}})]},proxy:true}])}),_c('Modal',{attrs:{"title":"Duplicate Players Found","details":{
      title: _vm.fullName,
      text: 'A player with this name already exists. Select an existing player from the list or create a new player with the same name.'
    }},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('submit-buttons',{attrs:{"loading":_vm.form.isLoading},on:{"save":_vm.handleDuplicatePlayerSave,"cancel":_vm.handleDuplicatePlayerCancel},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-button',{staticClass:"mr-1",on:{"click":_vm.handleDuplicatePlayerCreateNew}},[_vm._v(" Create New ")])]},proxy:true}])})]},proxy:true}]),model:{value:(_vm.modalDuplicatedPlayers),callback:function ($$v) {_vm.modalDuplicatedPlayers=$$v},expression:"modalDuplicatedPlayers"}},[_c('b-form-group',{attrs:{"for":"duplicate-player","label":"Select Player"}},[_c('b-form-select',{attrs:{"name":"duplicate-player","select-size":4},on:{"input":function($event){_vm.selected = { item: $event }}}},_vm._l((_vm.duplicatedPlayers),function(option){return _c('option',{key:option.id,domProps:{"value":option}},[_vm._v(" "+_vm._s(_vm.renderSuggestion({ item: option }, true))+" ")])}),0)],1)],1),_c('Modal',{attrs:{"title":"Player Already On Team","details":{
      title: _vm.fullName,
      text: "This player is already on a team for this season. Do you still want to add them?"
    },"submit-buttons":{ save: { text: 'Yes' } }},on:{"save":_vm.handlePlayerAlreadyOnTeamSave,"cancel":function($event){_vm.modalPlayerAlreadyOnTeam = false}},model:{value:(_vm.modalPlayerAlreadyOnTeam),callback:function ($$v) {_vm.modalPlayerAlreadyOnTeam=$$v},expression:"modalPlayerAlreadyOnTeam"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }