<template>
  <b-form-group
    label="Status"
    label-for="roster-status"
  >
    <b-form-select
      id="roster-status"
      name="status"
      :value="value"
      :options="options"
      :disabled="$attrs['disabled'] || !isFilter && (['traded', 'released'].includes(value))"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormSelect,
  },
  props: {
    value: {
      type: String,
      default: 'active',
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      let options = ['Active', 'Inactive']

      if (this.isFilter || this.value === 'traded') {
        options.push('Traded')
      }

      if (this.isFilter || this.value === 'released') {
        options.push('Released')
      }

      options = options.map(option => ({
        text: option,
        value: option.toLowerCase(),
      }))

      if (this.isFilter) {
        options.unshift('All')
      }

      return options
    },
  },
}
</script>

<style scoped>

</style>
