import { render, staticRenderFns } from "./FormValidationJerseyNumber.vue?vue&type=template&id=6af4f3ae&scoped=true&"
import script from "./FormValidationJerseyNumber.vue?vue&type=script&lang=js&"
export * from "./FormValidationJerseyNumber.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af4f3ae",
  null
  
)

export default component.exports