<template>
  <section>
    <modal-card title="Add Player">
      <template #default="{ isCard }">
        <validation-form
          ref="validationForm"
          v-slot="{ observer }"
          @before-validation="validateName"
          @validated="checkForDuplicatePlayers"
        >
          <b-form-row>
            <!-- First & Last Name -->
            <b-col
              v-for="(name, key) in { firstName: 'first_name', lastName: 'last_name' }"
              :key="key"
              sm="6"
              md="2"
            >
              <form-validation-auto-complete
                ref="name"
                v-model="form[name]"
                :name="inputProps[key].name"
                :input-props="inputProps[key]"
                :suggestions="suggestionsWithoutDuplicatePlayers"
                :label="inputProps[key].placeholder + '*'"
                :render-suggestion="renderSuggestion"
                :get-suggestion-value="({ item }) => item[name]"
                :is-loading="loadingSuggestions[name]"
                rules="required"
                @input="handleNameInput(name)"
                @selected="handleSelectedPlayer"
              />
            </b-col>

            <!-- Team -->
            <b-col
              sm="6"
              md="2"
              lg="2"
            >
              <select-team
                v-model="form.roster.team_id"
                rules="is_not:Select"
                :team.sync="team"
              />
            </b-col>

            <!-- Jersey Number -->
            <b-col
              sm="2"
              md="1"
              lg="1"
            >
              <form-validation-jersey-number v-model="form.roster.jersey_number" />
            </b-col>

            <!-- Position -->
            <b-col
              sm="4"
              md="1-5"
              lg="1"
            >
              <select-position
                v-model="form.roster.position_id"
                :sport="event.sport"
                @position-selected="position = $event"
              />
            </b-col>

            <!--      &lt;!&ndash; Status &ndash;&gt;-->
            <!--      <b-col sm="1">-->
            <!--        <label for="status">Status</label>-->
            <!--        <b-form-select-->
            <!--          v-model="form.status"-->
            <!--          name="status"-->
            <!--          :options="statusesOptions"-->
            <!--        />-->
            <!--      </b-col>-->

            <!-- Buttons -->
            <portal
              to="modal-footer-add-player"
              :disabled="isCard"
            >
              <submit-buttons
                class="mt-sm-2 ml-sm-75"
                :loading="form.isLoading"
                :invalid="observer.failed || debounceWaiting"
                v-on="$listeners"
                @save="$refs.validationForm.validate()"
              />
            </portal>
          </b-form-row>
        </validation-form>
      </template>

      <template #modal-footer>
        <portal-target name="modal-footer-add-player" />
      </template>
    </modal-card>

    <!-- Duplicate Player Modal -->
    <Modal
      v-model="modalDuplicatedPlayers"
      title="Duplicate Players Found"
      :details="{
        title: fullName,
        text: 'A player with this name already exists. Select an existing player from the list or create a new player with the same name.'
      }"
    >
      <!-- Duplicate Players -->
      <b-form-group
        for="duplicate-player"
        label="Select Player"
      >
        <b-form-select
          name="duplicate-player"
          :select-size="4"
          @input="selected = { item: $event }"
        >
          <option
            v-for="option in duplicatedPlayers"
            :key="option.id"
            :value="option"
          >
            {{ renderSuggestion({ item: option }, true) }}
          </option>
        </b-form-select>
      </b-form-group>

      <template #modal-footer>
        <submit-buttons
          :loading="form.isLoading"
          @save="handleDuplicatePlayerSave"
          @cancel="handleDuplicatePlayerCancel"
        >
          <template #prepend>
            <b-button
              class="mr-1"
              @click="handleDuplicatePlayerCreateNew"
            >
              Create New
            </b-button>
          </template>
        </submit-buttons>
      </template>
    </Modal>

    <!-- Player Already On Team Modal -->
    <Modal
      v-model="modalPlayerAlreadyOnTeam"
      title="Player Already On Team"
      :details="{
        title: fullName,
        text: `This player is already on a team for this season. Do you still want to add them?`
      }"
      :submit-buttons="{ save: { text: 'Yes' } }"
      @save="handlePlayerAlreadyOnTeamSave"
      @cancel="modalPlayerAlreadyOnTeam = false"
    />
  </section>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormRow, BFormSelect,
} from 'bootstrap-vue'
import ModalCard from '@/components/modals/ModalCard.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormValidationAutoComplete from '@/components/forms/validation/FormValidationAutoComplete.vue'
import FormValidationJerseyNumber from '@/components/forms/validation/FormValidationJerseyNumber.vue'
import SelectTeam from '@/components/forms/selects/SelectTeam.vue'
import SelectPosition from '@/components/forms/selects/SelectPosition.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'
import Modal from '@/components/modals/Modal.vue'

import { mapGetters, mapState } from 'vuex'
import Form from '@/forms/Form'
import useFlash from '@/composables/useFlash'
import { formatDate } from '@core/utils/filter'

const { flashError } = useFlash()

export default {
  components: {
    BFormRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    ValidationForm,
    ModalCard,
    FormValidationAutoComplete,
    FormValidationJerseyNumber,
    SelectTeam,
    SelectPosition,
    SubmitButtons,
    Modal,
  },
  data() {
    return {
      form: new Form({
        first_name: '',
        last_name: '',
        gender: null,
        roster: {
          jersey_number: '',
          position_id: null,
          status: 'active',
          team_id: 'Select',
          season_id: null,
        },
      }),
      team: null,
      position: null,
      inputProps: {
        firstName: {
          id: 'first-name',
          placeholder: 'First Name',
          class: ['form-control'],
          name: 'first_name',
        },
        lastName: {
          id: 'last-name',
          placeholder: 'Last Name',
          class: ['form-control'],
          name: 'last_name',
        },
      },
      closeFirstName: true,
      modalDuplicatedPlayers: false,
      modalPlayerAlreadyOnTeam: false,
      timeout: null,
      selected: null,
      debounceMilliseconds: 500,
      suggestions: [],
      loadingSuggestions: {
        first_name: false,
        last_name: false,
      },
      debounceWaiting: false,
    }
  },
  computed: {
    ...mapState('event', ['event']),
    ...mapState('event/seasons', ['season']),
    ...mapGetters('event', ['isMaleFemale']),
    fullName() {
      return `${this.form.first_name} ${this.form.last_name}`
    },
    duplicatedPlayers() {
      if (!this.suggestions.length) {
        return []
      }

      return this.suggestions[0].data
        .filter(player => player.full_name.toLowerCase().trim() === this.fullName.toLowerCase().trim())
    },
    suggestionsWithoutDuplicatePlayers() {
      if (!this.suggestions.length) {
        return []
      }

      return [{
        data: this.suggestions[0].data.filter(
          (suggestion, index, array) => array.findIndex(player => (player.full_name === suggestion.full_name)) === index,
        ),
      }]
    },
  },
  mounted() {
    if (!this.isMaleFemale) {
      this.form.gender = this.event.gender
    }

    this.form.roster.season_id = this.season.id
    this.form.keepDataWhenResetting = ['roster', 'gender']
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
    this.focusFirstNameInput()
  },
  methods: {
    // Inputs
    focusFirstNameInput() {
      document.getElementById('first-name').focus()
    },
    validateName() {
      this.$refs.name.forEach(name => {
        name.validateInput()
      })
    },
    // AutoSuggest
    renderSuggestion(suggestion, appendDuplicateIdentifier = false) {
      const player = suggestion.item
      let append = ''

      if (appendDuplicateIdentifier) {
        const appendList = [formatDate(player.birthdate), player.email, player.school].filter(a => a)

        append = (appendList.length ? ` (${appendList.join(', ').trim()})` : '')
      }

      return player.full_name + append
    },
    clearSuggestions() {
      this.suggestions = []
      this.selected = null
    },
    handleNameInput(key) {
      // This is so user cannot hit the save button before loading suggestions and checking for duplicates.
      this.debounceWaiting = true

      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        // Ignore blank data.
        if (!this.form[key]) {
          this.clearSuggestions()
          return
        }

        this.loadingSuggestions[key] = true

        const query = new URLSearchParams({
          organization_id: this.event.organization_id,
          [key]: this.form[key],
        })

        // Only show players genders that match the event's gender.
        if (!this.isMaleFemale) {
          query.append('gender', this.event.gender)
        }

        this.$http.get(`players?${query.toString()}`)
          .then(response => {
            this.clearSuggestions()

            this.suggestions.push({ data: response.data.data })
          })
          .catch(error => {
            flashError(error)
          })
          .finally(() => {
            this.loadingSuggestions[key] = false
            this.debounceWaiting = false
          })
      }, this.debounceMilliseconds)
    },
    handleSelectedPlayer(selected) {
      // Prevent from running when user hits the "enter" key.
      if (!selected) {
        return
      }

      this.selected = selected

      Object.assign(this.form, {
        first_name: selected.item.first_name,
        last_name: selected.item.last_name,
        gender: selected.item.gender,
      })

      // Validate inputs after new values otherwise input shows validation error.
      this.$nextTick(() => {
        this.validateName()
      })
    },
    // Duplicate Player Modal
    handleDuplicatePlayerSave() {
      this.handleSelectedPlayer(this.selected)
      this.suggestions = []
      this.$refs.validationForm.validate()
      this.modalDuplicatedPlayers = false
    },
    handleDuplicatePlayerCancel() {
      this.selected = null
      this.modalDuplicatedPlayers = false
    },
    handleDuplicatePlayerCreateNew() {
      this.selected = null
      this.modalDuplicatedPlayers = false
      this.addPlayer()
    },
    // Player On Same Team Modal
    handlePlayerAlreadyOnTeamSave() {
      this.modalPlayerAlreadyOnTeam = false
      this.addPlayer()
    },
    // Player Validation
    async checkForDuplicatePlayers() {
      if (this.debounceWaiting) {
        return
      }

      // Show duplicate player modal if user types a player's name without selecting from the suggestions and 1 suggestion exists or if user selects the player and 2 suggestions exists.
      if ((!this.selected && this.duplicatedPlayers.length) || (this.selected && this.duplicatedPlayers.length > 1)) {
        this.modalDuplicatedPlayers = true
        return
      }

      // Check if selected player is already added to season or a team.
      if (this.selected && await this.playerIsAlreadyAdded()) {
        return
      }

      this.addPlayer()
    },
    async playerIsAlreadyAdded() {
      let invalid = false
      this.form.isLoading = true

      try {
        const { data } = await this.$http.get(`players/${this.selected.item.id}?season_id=${this.season.id}`)
        const rosters = data.data.rosters.filter(roster => roster.status !== 'traded')

        // Check if player is already added onto team.
        if (rosters.filter(roster => roster.team?.id == this.form.roster.team_id).length) {
          this.$refs.name[0].$refs.autocomplete.$refs.validation.setErrors(['This player is already on this team.'])
          await this.$refs.name[0].validateInput({ valid: false })

          invalid = true
        } else if (rosters.length) {
          // Check if player is already added to the season.
          invalid = true
          this.modalPlayerAlreadyOnTeam = true
        }
      } catch (error) {
        const { response } = error

        if (!response?.status || response.status !== 422) {
          flashError(error)
          invalid = true
        }
      }

      this.form.isLoading = false

      return invalid
    },
    // Ajax
    addPlayer() {
      let url = '/players'
      let method = 'post'

      // Check if new or existing player.
      if (this.selected) {
        url = `${url}/${this.selected.item.id}`
        method = 'put'
      }

      this.form[method](`${url}?organization_id=${this.event.organization_id}`)
        .then(response => {
          this.$emit('add-player', {
            ...response.data,
            roster: {
              ...response.data.roster,
              division: this.team?.division,
              team: this.team,
            },
          })

          // Reset form.
          this.selected = null
          this.form.reset()
          this.clearSuggestions()
          this.focusFirstNameInput()
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .col-md-1-5 {
    flex: 0 0 10.3%;
    max-width: 10.3%;
  }
}
</style>
